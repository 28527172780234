import config from 'Data';
import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, Head, PageTitle } from 'Common';
import { Hero, Details, Socials } from 'Components/AboutPage';
import { FullContainer } from 'Common';

import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';

const About = () => (
  <StaticQuery
    query={graphql`
      query AboutImageQuery {
        AboutImage: imageSharp(fluid: { originalName: { regex: "/me.jpg/" } }) {
          fluid(maxWidth: 630) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Head type="Organization" location="/about">
          About | {config.legalName}
        </Head>
        <Hero />
        <FullContainer>
          <Details />
          {/* <Container>
            <Row>
              <Col>
                <a href={data.AboutImage.fluid.src}>
                  <Img fluid={data.AboutImage.fluid} alt="just me chilling" />
                </a>
              </Col>
            </Row>
          </Container> */}
          {/* <Socials /> */}
        </FullContainer>
      </Layout>
    )}
  />
);

export default About;

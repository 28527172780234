import config from 'Data';
import React from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'Common';
import Envelope from 'Static/about/envelope.svg';
import Phone from 'Static/about/phone.svg';
import Marker from 'Static/about/marker.svg';
import EnvelopeWhite from 'Static/about/envelope-white.svg';
import PhoneWhite from 'Static/about/phone-white.svg';
import MarkerWhite from 'Static/about/marker-white.svg';
import {
  Button,
  Collapse,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';
import { Img, Text } from './styles';

const Details = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <Container className="py-5">
        <Row>
          <Col>
            <Text>
              Nutriheal was founded in 2019 with the primary goal of improving
              health and wellness with food and adequate nutrition because food
              can heal. We provide Medical Nutrition Therapy (MNT), which is a
              therapeutic approach to treating medical conditions with tailored
              diets planned by a registered dietitian and monitored by a
              physician. We also provide wellness workshops, menus, and recipe
              analysis, rating, and modification. All services provided are
              rendered or supervised by a registered dietitian. We provide
              individual as well as institutional food, nutrition, health, and
              wellness consulting services.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text>
              <Img src={Envelope} alt="email" />
              <a href={'mailto:' + config.contact.email} target="_top">
                {config.contact.email}
              </a>{' '}
            </Text>
            <Text>
              <Img src={Phone} alt="phone" />
              <a href={'tel://' + config.contact.phone1} target="_top">
                {config.contact.phone1}
              </a>{' '}
              -{' '}
              <a href={'tel://' + config.contact.phone2} target="_top">
                {config.contact.phone2}
              </a>{' '}
            </Text>
            <Text>
              <Img src={Marker} alt="address" />
              {config.contact.address}
            </Text>
          </Col>
        </Row>
      </Container>
    )}
  </ThemeContext.Consumer>
);

export { Details };

import React from 'react';
import { FullContainer, ThemeContext } from 'Common';

import { Container, Row, Col } from 'reactstrap';
import { HeroSection } from 'Theme/styles';
import HeroBG from 'Static/background/thepearl-3.jpg';

const Hero = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <FullContainer>
        <HeroSection className="text-md-center" bg={HeroBG}>
          <Container>
            <Row>
              <Col>
                <h1>About us</h1>
                <p>The Nutritional & Wellness Center</p>
              </Col>
            </Row>
          </Container>
        </HeroSection>
      </FullContainer>
    )}
  </ThemeContext.Consumer>
);

export { Hero };
